export const components = {
    webhook: {
        title: 'Webhook',
        integration: {
            placeholder: 'Insert integration name',
            title: 'Integration',
        },
        url: {
            placeholder: 'Insert webhook url',
            title: 'URL'
        },
        error: {
            description: 'Error while sending test message. Response:',
            title: 'Error'
        },
        success: {
            description: 'Sending Test Message Successful. Response:',
            title: 'Success'
        },
        send: 'Send Test Message'
    },
};
