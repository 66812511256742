export const ROUTE_IAY_INTERFACE = 'iay_interface';


export default [
    {
        path: '/industry-and-you/interface',
        name: ROUTE_IAY_INTERFACE,
        component: () => import(
            /* webpackChunkName: "iay_interface" */
            '@/industry_and_you/Interface.vue'
        )
    },
];
