export const update = {
    errors: {
        notFound: {
            description: 'Could not find the notification you are searching for. Try another one.',
            title: 'Notification not found',
        },
        updating: {
            description: 'There was an error while updating. Please try again at a later time.',
            title: 'Error updating',
        },
        deleteing: {
            description: 'There was an error while deleting. Please try again at a later time.',
            title: 'Error deleting',
        },
    },
    danger: {
        description: 'Description',
        delete: 'Deleting the Notification will remove it from all configured locations. This cannot be reversed.'
    },
    generalInformation: {
        title: 'General Information',
        name: {
            placeholder: 'Insert notification name',
            title: 'Name',
        },
    },
    title: 'Update Notification',
}
