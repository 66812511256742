export const update = {
    errors: {
        notFound: {
            description: 'Could not find the blueprint stock you are searching for. Try another one.',
            title: 'Stock not found',
        },
        updating: {
            description: 'There was an error while updating. Please try again at a later time.',
            title: 'Error updating',
        },
        deleteing: {
            description: 'There was an error while deleting. Please try again at a later time.',
            title: 'Error deleting',
        },
    },
    success: {
        update: {
            description: 'Saving the stock was successful',
            title: 'Success',
        }
    },
    danger: {
        description: 'Description',
        delete: 'Deleting the stock will remove it completly. This cannot be reversed.'
    },
    notifications: {
        title: 'Notifications',
        add: 'Add Notification',
    },
    generalInformation: {
        addThreshold: 'Add Threshold',
        description: 'Description',
        name: 'Name',
        title: 'General information',
    },
    thresholds: {
        item: 'Item',
        want: 'Want',
        critical: 'Critical',
        minRuns: 'Min Runs',
        minMe: 'Min ME',
        minTe: 'Min TE',
        title: 'Thresholds'
    },
};
