export const update = {
    errors: {
        notFound: {
            description: 'Could not find the structure you are searching for. Try another one.',
            title: 'Structure not found',
        },
        updating: {
            description: 'There was an error while updating. Please try again at a later time.',
            title: 'Error updating',
        },
        deleteing: {
            description: 'There was an error while deleting. Please try again at a later time.',
            title: 'Error deleting',
        },
    },
    danger: {
        description: 'Description',
        deleteStructure: 'Deleting the structure will remove it from all groups. This cannot be reversed.'
    },
    generalInformation: {
        location: 'Location',
        name: 'Name',
        title: 'General Information',
        structureGroups: 'Structure Groups'
    },
    removeSuccess: 'Structure removed',
    removeError: 'Error while removing the structure',
    updateSuccess: 'Structure updated',
    updateError: 'Error while updating the structure',
};
