export const create = {
    errors: {
        resolveStructure: {
            description: 'Could not resolve structure id. Make sure you have docking access.',
            title: 'Error resolving structure',
        },
        unexpected: {
            description: 'Unexpected error while adding the structure.',
            title: 'Error adding structure',
        },
    },
    generalInformation: {
        description: 'Based on the given Structure ID the Name, System and Structure Type will be automatically determined. This requires that you have docking access to the strucutre.',
        group: 'Group the structure is available in. Multiple possible',
        structureId: 'EVE Structure ID',
        structureName: 'Name of the structure',
        structureNamePlaceholder: 'Structure Name',
        structureType: 'Structure Type',
        system: 'Structure System',
        title: 'General Information',
    },
    title: 'Create Structure',
    createStructure: 'Create',
    createSuccess: 'Structure added successfully',
    createError: 'Error adding structure',
    createErrorMessage: 'Unexpected error while adding the structure.',
};
