import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { PROJECT_ROUTE, ROUTE_CHANGE } from '@/event_bus';
import { events } from '@/main'

import character_routes from '@/characters/router';
import industry_and_you from '@/industry_and_you/router';
import industry_routes from '@/industry/router';
import notification_routes from '@/notification/router';
import project_routes, { ROUTE_PROJECT_ASSIGNMENTS, ROUTE_PROJECT_READY_JOBS } from '@/project/router';
import stock_routes from '@/stock/router';
import structure_dynamic_groups_routes from '@/structure_dynamic_group/router';
import structure_groups_routes from '@/structure_group/router';
import structure_routes from '@/structure/router';

const routes: Array<RouteRecordRaw> = [
    ...character_routes,
    ...industry_and_you,
    ...industry_routes,
    ...notification_routes,
    ...project_routes,
    ...stock_routes,
    ...structure_dynamic_groups_routes,
    ...structure_groups_routes,
    ...structure_routes,
    {
        path: '/',
        redirect: '/projects'
    },
    {
        path: '/legal',
        name: 'legal',
        component: () => import(
            /* webpackChunkName: "legal" */
            '@/Legal.vue'
        )
    },
    {
        path: '/docs/api',
        name: 'api',
        component: () => import(
            /* webpackChunkName: "api" */
            '@/Api.vue'
        )
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.afterEach((to, _) => {
    if (
        to &&
        to.name &&
        to?.name?.toString().indexOf('project_') >= 0 &&
        [
            ROUTE_PROJECT_ASSIGNMENTS,
            ROUTE_PROJECT_READY_JOBS,
        ].indexOf(to?.name?.toString()) === -1
    ) {
        events.$emit(PROJECT_ROUTE, to.name.toString());
    } else {
        document.title = 'caph';
        events.$emit(ROUTE_CHANGE, to?.name?.toString());
    }
});

export default router;
