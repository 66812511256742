import { create } from "./create/locales.en";
import { overview } from "./overview/locales.en";
import { update } from "./update/locales.en";

export const structure = {
    create,
    overview,
    update,

    components: {
        installedRigs: {
            title: 'Installed Rigs',
            slot: 'Slot {number}',
        },

        installedServices: {
            title: 'Installed Services',
            slot: 'Slot {number}',
        },

        resolveStructure: {
            resolveStructure: 'Resolve structure ID',
            structureIdPlaceholder: 'Structure ID',
        },

        structureRigSelector: {
            clear: 'Clear',
        },

        structureServiceSelector: {
            clear: 'Clear',
        },
    },

    modals: {
        resolveStructureInfo: {
            title: 'Resolve Structure ID',
            structureInfo: '1. Open the info of the structure',
            dragStructure: '2. Drag the image of the structure into a chat and send the message',
            copy: '3. Right Click > Copy',
            insert: '4. Paste into the Textbox',
        },
    },
}
