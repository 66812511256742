import { components } from "./en.components";
import { general } from "./en.general";
import { notification } from "@/notification/locales.en";
import { project } from "@/project/locale.en";
import { stock } from "@/stock/locales.en";
import { structure } from "@/structure/locales.en";

export const messages = {
    en: {
        components,
        general,
        notification,
        project,
        stock,
        structure,
    }
}
