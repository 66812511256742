export const create = {
    errors: {
        unexpected: {
            description: 'Unexpected error while creating the notification.',
            title: 'Error creating notification',
        }
    },
    actionGroup: {
        create: 'Create Notification'
    },
    generalInformation: {
        title: 'General Information',
        name: {
            placeholder: 'Insert notification name',
            title: 'Name',
        },
    },
    title: 'Create Notification',
}
