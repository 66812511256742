export const overview = {
    errors: {
        loadInitial: {
            description: 'Unknown error while loading structures. Please try again later.',
            title: 'Loading Error',
        },
    },
    filters: {
        name: 'Name',
        rigs: 'Rigs',
        services: 'Services',
        systemId: 'System',
        typeId: 'Structure',
    },
    addStructure: 'Add structure',
    title: 'All Structures',
    location: 'Location',
    name: 'Name',
    noStructures: 'No Structures yet.',
    rigs: 'Rigs',
    services: 'Services',
    type: 'Type',
};
