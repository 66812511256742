import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "nav-header-character"
}
const _hoisted_2 = { class: "nav-header-character-text" }
const _hoisted_3 = { style: {"margin-top":"5px","margin-left":"10px","color":"rgba(255, 255, 255, 0.2)","font-size":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_global_style = _resolveComponent("n-global-style")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_eve_icon = _resolveComponent("eve-icon")!
  const _component_n_layout_header = _resolveComponent("n-layout-header")!
  const _component_n_menu = _resolveComponent("n-menu")!
  const _component_n_layout_sider = _resolveComponent("n-layout-sider")!
  const _component_n_result = _resolveComponent("n-result")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_n_message_provider = _resolveComponent("n-message-provider")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_n_layout_footer = _resolveComponent("n-layout-footer")!
  const _component_n_layout = _resolveComponent("n-layout")!
  const _component_n_loading_bar_provider = _resolveComponent("n-loading-bar-provider")!
  const _component_n_notification_provider = _resolveComponent("n-notification-provider")!
  const _component_n_config_provider = _resolveComponent("n-config-provider")!

  return (_openBlock(), _createBlock(_component_n_config_provider, {
    theme: _ctx.dark,
    "theme-overrides": _ctx.themeOverrides,
    class: "font_overwrite"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_notification_provider, null, {
        default: _withCtx(() => [
          _createVNode(_component_n_loading_bar_provider, null, {
            default: _withCtx(() => [
              _createVNode(_component_n_global_style),
              _createVNode(_component_n_layout, { position: "absolute" }, {
                default: _withCtx(() => [
                  _createVNode(_component_n_layout_header, {
                    class: "header",
                    bordered: ""
                  }, {
                    default: _withCtx(() => [
                      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "nav-header-text" }, " EveForge ", -1)),
                      (!_ctx.is_logged_in())
                        ? (_openBlock(), _createBlock(_component_n_button, {
                            key: 0,
                            text: "",
                            onClick: _ctx.redirect_login
                          }, {
                            default: _withCtx(() => _cache[0] || (_cache[0] = [
                              _createTextVNode("Login with Eve")
                            ])),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      (_ctx.is_logged_in())
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.whoami.character_name), 1),
                            _createVNode(_component_eve_icon, {
                              id: _ctx.whoami.character_id,
                              character: ""
                            }, null, 8, ["id"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_n_layout, {
                    position: "absolute",
                    style: {"top":"64px"},
                    "has-sider": ""
                  }, {
                    default: _withCtx(() => [
                      (_ctx.is_logged_in())
                        ? (_openBlock(), _createBlock(_component_n_layout_sider, {
                            key: 0,
                            bordered: "",
                            "native-scrollbar": false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_n_menu, {
                                value: _ctx.current_route,
                                options: _ctx.options,
                                "expanded-keys": ['projects'],
                                "default-expanded-keys": ['projects']
                              }, null, 8, ["value", "options"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_n_layout, {
                        "content-style": "padding-left: 24px; padding-right: 24px;",
                        "native-scrollbar": false
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_n_message_provider, null, {
                            default: _withCtx(() => [
                              (!_ctx.is_logged_in() && !_ctx.no_login_required())
                                ? (_openBlock(), _createBlock(_component_n_result, {
                                    key: 0,
                                    title: "You are not logged in",
                                    description: "This tool requires you to login into your eve account",
                                    style: {"margin-top":"100px"}
                                  }, {
                                    footer: _withCtx(() => [
                                      _createVNode(_component_n_button, { onClick: _ctx.redirect_login }, {
                                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                                          _createTextVNode("Login with eve")
                                        ])),
                                        _: 1
                                      }, 8, ["onClick"])
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              (_ctx.is_logged_in() || _ctx.no_login_required())
                                ? (_openBlock(), _createBlock(_component_router_view, {
                                    style: {"margin-bottom":"50px"},
                                    key: _ctx.$route.fullPath
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_n_layout_footer, {
                            style: {"background-color":"rgb(16, 16, 20)","border-top":"1px solid rgba(255, 255, 255, 0.09)"},
                            position: "absolute"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, [
                                _cache[6] || (_cache[6] = _createTextVNode(" All ")),
                                _createVNode(_component_n_button, {
                                  style: {"font-size":"10px"},
                                  text: "",
                                  type: "info"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_router_link, {
                                      to: { name: 'legal' },
                                      style: {"color":"inherit","text-decoration":"none"}
                                    }, {
                                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                                        _createTextVNode(" Eve related materials ")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _cache[7] || (_cache[7] = _createTextVNode(" are property of ")),
                                _createVNode(_component_n_button, {
                                  href: 'https://www.ccpgames.com/',
                                  style: {"font-size":"10px"},
                                  tag: "a",
                                  target: "_blank",
                                  text: "",
                                  type: "info"
                                }, {
                                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                                    _createTextVNode(" CCP Games. ")
                                  ])),
                                  _: 1
                                }),
                                _cache[8] || (_cache[8] = _createTextVNode(" See ")),
                                _createVNode(_component_n_button, {
                                  style: {"font-size":"10px"},
                                  text: "",
                                  type: "info"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_router_link, {
                                      to: { name: 'legal' },
                                      style: {"color":"inherit","text-decoration":"none"}
                                    }, {
                                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                                        _createTextVNode(" legal notice. ")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["theme", "theme-overrides"]))
}