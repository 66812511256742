export const overview = {
    errors: {
        loadInitial: {
            description: 'There was an error loading the blueprint stocks',
            title: 'loadingError'
        }
    },
    filters: {
        name: 'Name',
    },
    list: {
        description: 'Description',
        name: 'Name',
    },
    actionGroup: {
        add: 'Add Stock'
    },
    noStocksConfigured: 'No stocks configured yet',
    title: 'Blueprint Stock'
};
