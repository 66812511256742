export const overview = {
    errors: {
        loadInitial: {
            description: 'There was an error loading the notifications',
            title: 'Loading Error'
        }
    },
    filters: {
        name: 'Name',
        target: 'Target',
    },
    list: {
        name: 'Name',
        target: 'Target',
        url: 'Url',
    },
    actionGroup: {
        add: 'Add Notification',
    },
    state: {
        noEntry: 'No notification configured yet'
    },
    title: 'Notifications'
};
