export const create = {
    errors: {
        creating: {
            description: 'There was an error while creating. Please try again at a later time.',
            title: 'Error creating',
        },
    },
    success: {
        create: {
            description: 'Creating the stock was successful',
            title: 'Success',
        }
    },
    notifications: {
        title: 'Notifications',
        add: 'Add Notification',
    },
    generalInformation: {
        addThreshold: 'Add Threshold',
        description: 'Description',
        name: 'Name',
        title: 'General information',
    },
    thresholds: {
        item: 'Item',
        want: 'Want',
        critical: 'Critical',
        minRuns: 'Min Runs',
        minMe: 'Min ME',
        minTe: 'Min TE',
        title: 'Thresholds'
    },
    title: 'Create Blueprint Stock'
};
