import { createApp } from 'vue'
import { createI18n } from 'vue-i18n';
import { Events } from '@/event_bus';
import { messages } from '@/locales/translations';

import * as Sentry from "@sentry/vue";

import App from './App.vue'
import router from './router'
import { error } from 'cypress/types/jquery';

export const events = new Events();

export const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    legacy: false,
    //missingWarn: false,
    messages,
});

let app = createApp(App);

/*Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/\*\.caph\.xyz\/api/],
    replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0,
    replaysOnErrorSampleRate: 1.0,
});*/

app.use(router)
    .use(i18n)
    .mount('#app');
