export const general = {
    add: 'Add',
    back: 'Back',
    cancel: 'Cancel',
    close: 'Close',
    copy: 'Copy',
    create: 'Create',
    edit: 'Edit',
    loading: 'Loading ...',
    noEntries: 'No entries found',
    remove: 'Remove',
    delete: 'Delete',
    save: 'Save',
    searchResultEmpty: 'Your search did not result yield any results.',
}
