export const components = {
    alertSave: {
        description: 'Successfully saved',
        title: 'Sucess',
    },
    copyText: {
        copied: 'Copied!',
    },
    countdown: {
        done: 'Done',
    },
    commonMessages: {
        createSuccess: {
            title: 'Create Success',
            description: 'The entity was successfully created',
        },
        updateSuccess: {
            title: 'Update Success',
            description: 'The entity was successfully updated',
        },
        deleteError: {
            title: 'Delete Error',
            description: 'Error while deleting the entity. Try again later',
        },
        loadingError: {
            title: 'Loading Error',
            description: 'Error while loading data. Try again later',
        },
        updateError: {
            title: 'Update Error',
            description: 'Error while updating. Try again later',
        },
        notFound: {
            title: 'Not found',
            description: 'Entity not found, make sure the link is correct.',
        },
        forbidden: {
            title: 'Forbidden',
            description: 'You do not have the necessary permissions.',
        },
    },
    confirmDialog: {
        areYouSure: 'Are you sure you want to delete the entry? This cannot be reversed!',
        typeDelete: 'Please type in "delete" to confirm.',
    },
    deleteObject: {
        delete: 'Delete',
        title: 'Danger Zone',
    },
    formItem: {
        info: 'Info'
    },
    item: {
        loadError: 'Error loading item.'
    },
    itemExport: {
        copied: 'Copied!',
        title: 'Export',
    },
}
