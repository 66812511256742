export const project = {
    activeJobs: {
        activity: 'Activity',
        name: 'Name',
        runs: 'Runs',
        structureName: 'Structure',
        remaining: 'Remaining',
        endDate: 'End Date (Eve Time)',
        cost: 'Job cost (ISK)',
    },
    industryJobs: {
        activeJobs: 'Active Jobs',
    },
    market: {
        overview: {
            addEntry: 'Add Entry',
            bulkAddEntry: 'Bulk Add Entry',
            cost: 'Price',
            name: 'Name',
            noEntries: 'No market entries',
            quantity: 'Quantity',
            source: 'Source',
        }
    },
    marketCompressedMineralView: {
        compressedOreTable: {
            name: 'Name',
            quantity: 'Quantity',
        },
        mineralsTable: {
            name: 'Name',
            quantity: 'Quantity',
            overageQuantity: 'Overage Quantity',
            overagePrice: 'Overage Price',
        },
        compressedOres: 'Compressed Ores',
        compressedOreSet: 'Compressed ores are already set',
        loadCompressedOreError: 'Error loading recommended Compressed Ore',
        loadInitialError: 'Error loading project',
        reprocessedMinerals: 'Reprocessed Minerals',
        reprocessingEfficiency: 'Reprocessing Efficiency',
        saveCompressedOreError: 'Error saving compressed ore',
        savedCompressedOre: 'Compressed Ore successfully saved',
        setCompressedOre: 'Set compressed ore',
    },
    overview: {
        loadError: 'Unexpeted error loading the project',
        generalInfo: {
            title: 'General',
            orderer: 'Orderer',
            sellPrice: 'Sell Price',
            notes: 'Notes',
            products: 'Products',
        },
        financeInfo: {
            title: 'Finance',
            jobCost: 'Job Cost',
            marketCost: 'Market cost',
            miscellaneousCost: 'Miscellaneous costs',
            excessCost: 'Excess Cost',
            totalCost: 'Total Cost',
            sellPrice: 'Sell price',
            profit: 'Profit',
        },
        productInfo: {
            title: 'Products',
        }
    },
    jobs: {
        activeJobs: 'Active Jobs'
    },
    stock: {
        title: 'Stocks',
        updatePriceWith: 'Update price with ...',
        noEntries: 'No stock was used for the project',
        export: 'Export',
        table: {
            name: 'Item',
            quantity: 'Quantity',
            cost: 'Cost (Jita Sell)',
        },
    },
    excess: {
        title: 'Excess',
        updatePriceWith: 'Update price with ...',
        noEntries: 'No stock was used for the project',
        export: 'Export',
        table: {
            name: 'Item',
            quantity: 'Quantity',
            cost: 'Cost (Jita Sell)',
        },
    },
    modal: {
        addMarketEntry: {
            title: 'Add market entry',
            cost: {
                label: 'Cost',
                placeholder: 'Insert cost',
            },
            quantity: {
                label: 'Quantity',
                placeholder: 'Insert quantity',
            },
            source: {
                label: 'Source',
                placeholder: 'Insert source',
            },
        },
    },
    components: {
        appraisal: {
            selector: 'Update price with ...',
            internal: 'Internal Appraisal based on Jita sell',
            janice: 'Janice Appraisal based on Jita sell',
            selectorInternalOnly: 'Update price based on Jita sell'
        }
    },
    settings: {
        danger: {
            description: 'Once you delete a project, it cannot be reversed. Make sure that is what you want.',
            title: 'Delete this project',
        }
    },
}
