import { create as createBlueprints } from "./blueprint/create/locales.en"
import { overview as overviewBlueprints } from "./blueprint/list/locales.en"
import { update as updateBlueprints } from "./blueprint/update/locales.en"

export const stock = {
    components: {
        notifications: {
            add: 'Add',
            remove: 'Remove',
            name: 'Name',
            target: 'Target',
        },
    },

    blueprint: {
        create: createBlueprints,
        overview: overviewBlueprints,
        update: updateBlueprints,
        modals: {
            thresholds: {
                item: 'Blueprints that should be stocked',
                want: {
                    label: 'Number of blueprints that should be stocked',
                    placeholder: 'Want'
                },
                critical: {
                    label: 'Under this amount the stock is critical',
                    placeholder: 'Critical'
                },
                minRun: {
                    label: 'Number of runs the BPC needs to have left',
                    placeholder: 'Min runs'
                },
                minMe: {
                    label: 'Minimum Material Efficiency the BPC needs to have',
                    placeholder: 'Min Material Efficiency'
                },
                minTe: {
                    label: 'Minimum Time Efficiency the BPC needs to have',
                    placeholder: 'Min Time Efficiency'
                },
            }
        },
    }
}
