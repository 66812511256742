import { components } from '@/notification/components/locales.en';
import { create } from '@/notification/create/locales.en';
import { overview } from '@/notification/overview/locales.en';
import { update } from '@/notification/update/locales.en';

export const notification = {
    create,
    overview,
    update,

    components,
}
